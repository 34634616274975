import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { isMobile } from "web3modal";
import { ACTION_CONST, ROUTES } from "../../../constants";
import { getKYC } from "../../../redux/services/kyc.api";
import { helpers } from "../../../utils/helpers";
import exactMath from 'exact-math';
import { useActiveWeb3React } from "../../../hook";
import { AMOUNT_SHOW_KYC } from "../../../_configs";
import { useKYCStatus, useLatestBlock, useModalHelp, useStakeAmount, useWeb3Utils } from "../../../hook/useState";

const Header = () => {
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React()
  const showModalHelp = useModalHelp();
  const latestBlock = useLatestBlock();
  const web3Utils = useWeb3Utils();
  const stakedAmount = useStakeAmount();
  const kycStatus = useKYCStatus();
  const [polyPadBalance, setPolyPadBalance] = useState(0);

  //set balance
  useEffect(() => {
    if (web3Utils && account) {
      web3Utils.web3.eth.getBalance(account).then(balance => {
        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })
      web3Utils.getTokenPadBalance().then(data => {
        dispatch({
          type: ACTION_CONST.GET_BSCPAD_BALANCE,
          data: data
        })
        setPolyPadBalance(data)
      })
    }
  }, [account, web3Utils, latestBlock]);


  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {
        const url = data.url
        window.open(url, "_blank")
      }
    }).catch(err => {
      console.log(err);
    })
  }


  // //check show hide status
  useEffect(() => {
    if (!account) {
      return;
    }
    if ((Number(polyPadBalance) + Number(stakedAmount)) >= AMOUNT_SHOW_KYC) {
      getKYCAddress(account)
    }
  }, [account, polyPadBalance, stakedAmount, latestBlock])

  //get kyc
  const getKYCAddress = (address) => {
    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {
        const state = response.state;
        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }

  const handGotToProject = () => {
    dispatch({
      type: ACTION_CONST.CLEAR_INTERVAL_PROJECTS_JOB
    })
  }

  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-lg navbar-dark fixed-top pp-navbar">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center p-0" href="https://polypad.com/" target="_blank">
            <img src="/images/logo.svg" alt="PolyPad" />
          </a>
          <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
            {isMobile() &&
              <div className="dropdown d-block d-md-none d-lg-none d-xl-none">
                <button className="nav-link btn btn-sm btn-outline-primary btn-circle dropdown-toggle btn-helpmore" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="mdi mdi-help"></i>
                </button>
              </div>
            }
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={`navbar-nav ms-auto mb-2 mb-md-0 align-items-center ${account ? ' connected' : ''}`}>
              <li className="nav-item me-5">
                <NavLink activeClassName="active" className="nav-link" aria-current="page" to={ROUTES.PROJECTS} onClick={handGotToProject}>
                  <span>Projects</span>
                </NavLink>
              </li>
              <li className="nav-item me-5">
                <NavLink activeClassName="active" className="nav-link" aria-current="page" to={ROUTES.STACKING} >
                  <span>Staking</span>
                </NavLink>
              </li>

              <li className="nav-item me-5">
                <a href={`https://bridge.polypad.com/`} target="blank" aria-current="page" className="nav-link">
                  <span>Bridge</span>
                </a>
              </li>
              {
                !account ?
                  <li className="nav-item me-2">
                    <a className="btn btn-primary" href="#" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}>
                      <span>Connect Wallet</span>
                    </a>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2">
                      <a className="btn btn-primary" href="#" data-bs-toggle="modal" data-bs-target="#walletModal">
                        <span>{helpers.formatTransactionHash(account, 4, 4)} - <b>{helpers.formatNumberDownRoundWithExtractMax(polyPadBalance, 4)}</b> $PolyPad</span>
                      </a>
                    </li>
                    {
                      kycStatus === 'START' &&
                      <li className="nav-item">
                        <button className="btn btn-primary btn-kyc-start px-4 ms-2 text-white align-items-center d-inline-flex" onClick={() => handleOnclickKyc()}>
                          <i className="mdi mdi-pencil-plus me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                          <span >KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'PENDING' &&
                      <li className="nav-item">
                        <button className="btn btn-primary btn-kyc-pending px-4 ms-2 text-white align-items-center d-inline-flex" onClick={() => handleOnclickKyc()}>
                          <i className="mdi mdi-exclamation-thick me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                          <span>KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'APPROVED' &&
                      <li className="nav-item">
                        <button className="btn btn-primary btn-kyc-success px-4 ms-2 text-white align-items-center d-inline-flex">
                          <i className="mdi mdi-check-bold me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                          <span>KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'ERROR' &&
                      <li className="nav-item">
                        <button className="btn btn-primary btn-kyc-failed px-4 ms-2 text-white align-items-center d-inline-flex" onClick={() => handleOnclickKyc()}>
                          <i className="mdi mdi-alert-octagon-outline me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                          <span>KYC</span>
                        </button>
                      </li>
                    }
                  </>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;

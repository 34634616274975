import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { history } from "../../utils/history";
import { ACTION_CONST, ROUTES } from "../../constants";
import { actGetListProjects, actSelectedProject, actSetCurrentContract, } from "../../redux/action/user";
import { getCountDown } from "../../utils/helper";
import { helpers } from "../../utils/helpers";
import { useParams } from "react-router";
import { useInfoRound, useIsConnected, useLatestBlock, useModalHelp, useSelectedProject, useWeb3Utils } from "../../hook/useState";
import { useActiveWeb3React } from "../../hook";
import ScheduleTabComponent from "./components/ScheduleTabComponent";
import YourAllocationComponent from "./components/YourAllocationComponent";
import ApproveModal from "./modals/ApproveModal";
import InvestmentModal from "./modals/InvestModal";
import moment from 'moment'
import "react-block-ui/style.css";
import { Row, Col, Container } from "react-bootstrap";

const ProjectDetailPage = () => {
  const dispatch = useDispatch();
  let params = useParams();
  const { account } = useActiveWeb3React();
  const [ethBalance, setEthBalance] = useState(0);
  const [contractAddress, setContractAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [remainingAllocation, setMaxTokenAllocation] = useState(0);
  const [userParticipation, setUserParticipation] = useState(0);
  const [amountPurchased, setAmountPurchased] = useState(0);
  const [countBuy, setCountBuy] = useState(0);
  const [enableJoinProject, setEnableJoinProject] = useState(false);
  const [projectState, setProjectState] = useState(null);
  const [tier, setTier] = useState("");
  const [roundState, setRoundState] = useState(0);
  const [textRoundState, setTextRoundState] = useState('')
  const [roundTime, setRoundTime] = useState(0);
  const [allocationInfo, setAllocationInfo] = useState([])
  const [layoutAllocation, setLayoutAllocation] = useState(1)
  const [yourAllocationVisible, setYourAllocationVisible] = useState(false)
  const [allocationNumber, setAllocationNumber] = useState(0);
  const [enableApprove, setEnableApprove] = useState(false);
  const [walletInfo, setWalletInfo] = useState({
    remainingAllocation: 0,
    tokenBalance: 0,
  })

  useEffect(() => {
    const { contract } = params
    if (contract && typeof contract === "string") {
      setContractAddress(contract);
      dispatch(actSelectedProject(contract));
      dispatch(actSetCurrentContract(contract));
    } else {
      history.push(ROUTES.HOMEPAGE);
      return;
    }
  }, [])

  const latestBlock = useLatestBlock();

  const selectedProject = useSelectedProject();

  const showModalHelp = useModalHelp();

  const isConnectWallet = useIsConnected();

  const web3Utils = useWeb3Utils();
  const roundInfo = useInfoRound();

  const [closeTime, setCloseTime] = useState(0);
  const [openTime, setOpenTime] = useState(0)
  const [fcfsOpenTime, setFcfsOpenTime] = useState(0)

  //Job interval  
  useEffect(() => {
    if (contractAddress) {
      dispatch(actSelectedProject(contractAddress))
    }

  }, [latestBlock, contractAddress])

  useEffect(() => {
    if (selectedProject) {

      if (selectedProject.closeTime !== closeTime) {

        setCloseTime(selectedProject.closeTime);
      }
      if (selectedProject.openTime != openTime) {

        setOpenTime(selectedProject.openTime)
      }
      if (selectedProject.fcfsOpenTime != fcfsOpenTime) {
        // console.log("fcfsOpenTime==>", selectedProject.fcfsOpenTime);
        setFcfsOpenTime(selectedProject.fcfsOpenTime)
      }

      setYourAllocationVisible(selectedProject.yourAllocationVisible)
      setProjectState(selectedProject)
    }

  }, [selectedProject]);

  useEffect(() => {

    if (openTime > 0) {
      // console.log("call here")
      getCountDown(`idOpenTime-${selectedProject["contract"]}`, openTime * 1000, (job) => {

        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_OPEN,
          data: job
        })

      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }

  }, [openTime])

  useEffect(() => {
    if (closeTime > 0) {
      // console.log("idTimeClose==>", closeTime);

      getCountDown(`idTimeClose-${selectedProject["contract"]}`, closeTime * 1000, (job) => {

        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_CLOSE,
          data: job
        })
      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }
  }, [closeTime])

  useEffect(() => {
    if (fcfsOpenTime > 0) {
      // console.log("fcfsOpenTime==>", fcfsOpenTime);
      getCountDown(`idFcfsOpenTime-${selectedProject["contract"]}`, fcfsOpenTime * 1000, (job) => {

        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_FCFS_TIME,
          data: job
        })
      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }
  }, [fcfsOpenTime])





  useEffect(() => {

    //round time dc chay lai khi call wallet ==> sinh ra nhieu rountime nay
    if (roundTime > 0 && selectedProject) {
      getCountDown(`idRoundTime-${selectedProject["contract"]}`, roundTime * 1000, function start(job) {
        //do smt when countdown expired
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_ROUND,
          data: job
        })

      }, function end(job) {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))
        //do smt when countdown expired
      });
    }

  }, [roundTime])


  let count = 0;
  useEffect(() => {

    if (isConnectWallet && web3Utils && projectState) {
      dispatch(actGetListProjects())
      // console.log("count===>", count++);

      fetchData()

    }
    async function fetchData() {
      const { contract } = params

      if (contract) {
        await getWalletInfo(contract);
        await getAllowance();
        await getYourAllocation(contract)
      }
    }

  }, [isConnectWallet, web3Utils, countBuy, contractAddress, projectState, latestBlock]);





  const getWalletInfo = async (contract) => {
    if (web3Utils) {
      web3Utils.getInfoWallet(contract).then(

        data => {
          // console.log(projectState);
          setWalletInfo({
            remainingAllocation: data.remainingAllocation / 10 ** projectState.decimal,
            tokenBalance: data.tokenBalance / 10 ** projectState.decimal,
            ethBalance: data.ethBalance
          })

          setEthBalance(data.ethBalance);
          setTokenBalance(data.tokenBalance / 10 ** projectState.decimal);

          setUserParticipation(data.userParticipation / 10 ** projectState.decimal);
          setMaxTokenAllocation(data.remainingAllocation / 10 ** projectState.decimal);
          setTier(data.tier);
          setRoundState(data.roundState);
          setTextRoundState(data.roundStateText)

          if (document.getElementById('idTextRoundState')) {
            document.getElementById('idTextRoundState').innerHTML = data.roundStateText;
          }



          if (roundTime != data.roundTimestamp) {
            setRoundTime(data.roundTimestamp);
          }

          if (!(data.roundState === 1 || data.roundState === 3)) {
            // console.log('call here state ')
            setEnableJoinProject(false);
            return;
          }


          if (projectState.state === "C" || projectState.state === "P" || projectState.address === 'TBA') {
            setEnableJoinProject(false)
            return;
          }
          else {
            if (selectedProject.isPrivate) {
              if (data.remainingAllocation === 0) {

                setEnableJoinProject(false)
                return;
              } else {
                setEnableJoinProject(true)
                return;
              }
            } else {
              setEnableJoinProject(true)
              setMaxTokenAllocation(projectState.maxSingleParticipationAllocated)
              // remainingAllocation = maxSingleParticipationAllocated
            }
          }

        }
      ).catch(err => {

        console.log(err);
      })
    }

  }
  const getAllowance = async () => {
    web3Utils.getAllowance(selectedProject.tokenAddress, selectedProject.contract).then(data => {
      
      setAllocationNumber(Number(data))
    }).catch(err => {
      console.log(err);
    })
  }
  const getYourAllocation = (contract) => {
    web3Utils.getInfoAllocations(contract).then(data => {
      setLayoutAllocation(data.layout)
      setAllocationInfo(data.infoAllocation)
    }).catch(err => {
      console.log(err);
    })
  }

  //check enable disable approve button
  useEffect(() => {
    if (remainingAllocation > allocationNumber) {
      setEnableApprove(true)
    } else {
      setEnableApprove(false)
    }
  }, [allocationNumber, remainingAllocation])

  console.log("check==> ", (!enableJoinProject || allocationNumber == 0));
  return (
    <>
      {selectedProject ?
        <div className="pp-detail-page">
          <div className="pp-detail-header">
            <Container>
              <Row className="gx-xl-5">
                <Col lg="3" md="5" className="mb-md-0 mb-4">
                  <div className="pp-project-logo">
                    <img src={selectedProject.logo} alt={selectedProject.name} />
                  </div>
                </Col>
                <Col xl="5" lg="6" md="7">
                  <div className="pp-project-labels">
                    {(selectedProject.state === "O" || selectedProject.state === "F") && <span className="pp-project-status open"><i className="mdi mdi-circle"></i> Open</span>}
                    {selectedProject.state === "C" && <span className="pp-project-status closed"><i className="mdi mdi-circle"></i> Closed</span>}
                    {
                      selectedProject.state === "P" &&
                      <>
                        {
                          selectedProject.openTime !== undefined ?
                            <span className="pp-project-status opening"><i className="mdi mdi-circle"></i> Opens in&nbsp;<b id={`idOpenTime-${selectedProject["contract"]}`}>0d 0h 0m 0s</b></span>
                            : <span className="pp-project-status opening"><i className="mdi mdi-circle"></i>TBA</span>
                        }
                      </>
                    }
                    <div className="pp-project-symbol">{selectedProject.symbol}</div>
                  </div>
                  <h1 className="pp-project-name"><span>{selectedProject.name}</span></h1>
                  <div className="pp-project-description">{selectedProject.description}</div>
                  <div className="pp-project-socials">
                    {
                      selectedProject.pancakeswap &&
                      (<a href={selectedProject.pancakeswap} target="_blank">
                        <img height="20" src="/images/pancake-swap.png" />
                      </a>)
                    }
                    {
                      selectedProject.website &&
                      (<a href={selectedProject.website} target="_blank">
                        <i className="fas fa-globe-americas"></i>
                      </a>)
                    }
                    {
                      selectedProject.twitter &&
                      (<a href={selectedProject.twitter} target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>)
                    }
                    {
                      selectedProject.medium &&
                      (<a href={selectedProject.medium} target="_blank">
                        <i className="fab fa-medium-m"></i>
                      </a>)
                    }
                    {
                      selectedProject.telegram &&
                      (<a href={selectedProject.telegram} target="_blank">
                        <i className="fab fa-telegram-plane"></i>
                      </a>)
                    }
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="pp-detail-content">
            <div className="container">
              <div className="pp-p-intro">
                <div className="row">
                  <div className="col-md-6">
                    <div className="position-relative">
                      {selectedProject.state !== "P" && <div className="mb-4">
                        {account &&
                          <div className="pp-card-info mt-2">
                            <div className="pp-card-col w-100">
                              Your balance<br />
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <strong className="text-white detail-value" id="idBusdBalance">{helpers.formatNumberDownRound(tokenBalance, 4)} {selectedProject.symbol}</strong>
                              </div>
                              {selectedProject.symbol !== "MATIC" &&
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                  <strong id="idKcsBalance" className="text-white detail-value">{helpers.formatNumberDownRound(ethBalance, 4)} MATIC</strong>
                                </div>
                              }
                              <div className="pp-card-col mb-3 mb-md-0">
                                Your approved amount:<br />
                                <b className="text-white detail-value">{helpers.formatNumberDownRound(allocationNumber, 4)} {selectedProject.symbol}</b>
                              </div>

                            </div>
                            <div className="pp-card-col w-100 mt-3">
                              {tier && <div>
                                Your tier<br />
                                <b className="text-white detail-value">{tier}</b>
                              </div>}
                            </div>
                          </div>
                        }
                      </div>
                      }

                      {(selectedProject.state === "P" && account) && <div className="mb-4">
                        <div className="card card-img-holder bg-gradient-danger mx-auto mx-lg-0">
                          <div>
                            {account &&
                              <div className="pp-card-info mt-2">
                                <div className="pp-card-col">
                                  Your balance<br />
                                  {
                                    selectedProject.openTime !== undefined ?
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="text-white detail-value mb-0"><strong id="idBusdBalance">
                                          {helpers.formatNumberDownRound(tokenBalance, 4) || 0}&nbsp;
                                          {selectedProject.symbol}
                                        </strong></h4>
                                        {
                                          selectedProject.state !== "P" &&
                                          <h6 className="text-white detail-value" id="idBusdConvert">1 {selectedProject.symbol} = {selectedProject.rate} {selectedProject.projectTokenSymbol}</h6>
                                        }
                                      </div>
                                      : <div></div>
                                  }

                                  {selectedProject.symbol !== "MATIC" &&
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h4 className="text-white detail-value mb-0"><strong id="idethBalance">{helpers.formatNumberDownRound(ethBalance, 4) || 0} MATIC</strong></h4>
                                    </div>
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                    {
                      !account ?
                        <button type="button" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"} className="btn-pp">
                          <span>Connect Wallet</span>
                        </button>
                        :
                        <></>
                    }
                  </div>

                  <div className="col-md-6">
                    <div className="position-relative">
                      <div className="items-morex">
                        {(selectedProject.state === "O" || selectedProject.state === "F") &&
                          <>
                            <span className="pp-status-open text-success"><i className="mdi mdi-circle text-success"></i> {'Open'}</span>
                          </>
                        }
                        {
                          selectedProject.state === "P" ?
                            <>
                              {/* {
                                selectedProject.openTime !== undefined ?
                                  <span className="pp-status-opening text-warning" ><i className="mdi mdi-circle  text-warning"></i> {'Opens in'}&nbsp;<b className="text-white" id={`idOpenTime-${selectedProject["contract"]}`}>0d 0h 0m 0s</b></span>
                                  : <span className="pp-status-opening text-warning"><i className="mdi mdi-circle  text-warning"></i>{'TBA'}</span>
                              } */}
                            </> :
                            <div>
                              {/* If not connect show open time  else show round time.(if state = 4 ==> show closes)*/}
                              {
                                (selectedProject.state == "O") &&
                                <div className="pp-card-info mt-2">
                                  {account ?
                                    roundState !== 4 ?
                                      <div className="pp-card-col w-100">
                                        {
                                          textRoundState && <div>
                                            <span id="idTextRoundState"> {textRoundState}</span>
                                            <br />
                                          </div>
                                        }
                                        <b id={`idRoundTime-${selectedProject["contract"]}`} className="detail-value"> 0d 0h 0m 0s</b>
                                      </div> :
                                      <div className="items-morex">
                                        <i className="mdi mdi-circle me-2"></i>
                                        <span>ROUND CLOSED</span>
                                      </div>
                                    :
                                    <div className="pp-card-col w-100">First Come First Serve <u>opens</u> in:<br />
                                      <b id={`idFcfsOpenTime-${selectedProject["contract"]}`} className="detail-value"> 0d 0h 0m 0s</b>
                                    </div>
                                  }
                                </div>
                              }

                              {
                                selectedProject.state == "F" &&
                                <div className="pp-card-info mt-2">
                                  {account ?
                                    roundState !== 4 ?
                                      <div className="pp-card-col w-100">
                                        <span id="idTextRoundState"> {textRoundState}</span>
                                        <br />
                                        <b id={`idRoundTime-${selectedProject["contract"]}`} className="detail-value"> 0d 0h 0m 0s</b>
                                      </div> :
                                      <div className="items-morex">
                                        <i className="mdi mdi-circle me-2"></i>
                                        <span>ROUND CLOSED</span>
                                      </div>
                                    :
                                    <div className="pp-card-col w-100">Closing in:<br />
                                      <b id={`idTimeClose-${selectedProject["contract"]}`} className="detail-value"> 0d 0h 0m 0s</b>
                                    </div>
                                  }
                                </div>
                              }

                              {
                                selectedProject.state == "C" &&
                                <div className="pp-card-info mt-2 text-white">
                                  <div className="items-morex">
                                    <i className="mdi mdi-circle me-2"></i>
                                    <span>CLOSED</span>
                                  </div>
                                </div>
                              }

                              {
                                account &&
                                <div>
                                  <hr className="mb-2 mt-2" />
                                  <div className="pp-card-info">
                                    <div className="d-flex justify-content-between w-100">
                                      <div className="pp-card-col w-100">
                                        Swapped<br />
                                        <b id="idUseParticipation" className="detail-value">{helpers.formatNumberDownRound(userParticipation, 4)} {selectedProject.symbol}</b>
                                        <div>
                                          <b className="detail-value"> {`${helpers.formatNumberDownRound(userParticipation * selectedProject.rate, 4)} ${selectedProject.projectTokenSymbol}`}</b>
                                        </div>
                                      </div>
                                      <div className="pp-card-col w-100">
                                        Remaining Allocation<br />
                                        <b id="idBusdMaxBuy" className="detail-value">{helpers.formatNumberDownRound(remainingAllocation, 4)} {selectedProject.symbol}</b>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              <hr className="mb-2 mt-2" />
                              <div className="pp-card-info">
                                <div className="d-flex justify-content-between w-100">
                                  {/* Progress bar */}
                                  {selectedProject.state !== "O" ?
                                    <div className="pp-card-col w-100">
                                      Swap progress<br />
                                      <div className="pp-card-progress">
                                        <div className="pp-card-progress-percent" style={{ width: `${(selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                        <div className="pp-card-progress-label">
                                          <span><b>{((selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                          <span><b>{selectedProject.totalFundParticipated.toFixed(4)}/{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</b></span>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <div className="pp-card-col w-100 pp-card-progress-wrap light-progress disabled">
                                      <div className="pp-card-progress text-center">
                                        <div className="pp-card-progress-percent pp-card-progress-percent-card"></div>
                                        <div className="pp-card-progress-label">
                                          <span className="participants-center" >Allocation round</span>
                                          <span className="participants-center" style={{ top: "8px" }}>{selectedProject.totalCountUserParticipated} Participants</span>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>


                {account ?
                  <div className="pt-4 mt-4" style={{ borderTop: '1px rgba(255,255,255,.1) solid' }}>
                    {selectedProject.state !== "P" &&
                      <div className="d-flex flex-wrap" style={{ gap: '20px' }}>
                        <button type="button" disabled={!enableApprove} data-bs-toggle="modal" data-bs-target="#approveModal" className="btn-pp">
                          <span>Approve</span>
                        </button>
                        <button type="button" disabled={!enableJoinProject || allocationNumber == 0} data-bs-toggle="modal" data-bs-target="#buyModal" className="btn-pp">
                          <span>Join Pool</span>
                        </button>
                      </div>
                    }
                  </div> : <></>
                }

              </div>

              <div className="tab-content mt-5" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="py-3">
                    <div className="row gx-xl-5">
                      <div className="col-lg-6">
                        <div className="pp-p-info h-fill">
                          <div className="pp-allocation-title">
                            <h4 className="pp-title-section info text-start fs-32 mb-4">Pool information</h4>
                          </div>
                          <div className="table-responsivex">
                            <table className="table mb-0 pp-table-info text-white">
                              <tbody>
                                <tr>
                                  <td>Opens</td>
                                  {
                                    selectedProject.openTime === undefined ?
                                      <td className="text-end">
                                        <h5 className="mb-0">TBA</h5>
                                      </td>
                                      : <td className="text-end">
                                        <h5 className="mb-0">{moment(selectedProject.openTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                                      </td>
                                  }
                                </tr>
                                <tr>
                                  <td>FCFS Opens</td>
                                  {
                                    selectedProject.fcfsOpenTime === undefined ?
                                      <td className="text-end">
                                        <h5 className="mb-0">TBA</h5>
                                      </td>
                                      : <td className="text-end">
                                        <h5 className="mb-0">{moment(selectedProject.fcfsOpenTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                                      </td>
                                  }
                                </tr>
                                <tr>
                                  <td>Closes</td>
                                  {
                                    selectedProject.closeTime === undefined ?
                                      <td className="text-end">
                                        <h5 className="mb-0">TBA</h5>
                                      </td>
                                      : <td className="text-end">
                                        <h5 className="mb-0">{moment(selectedProject.closeTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                                      </td>
                                  }
                                </tr>
                                <tr>
                                  <td>Swap Rate</td>
                                  {
                                    selectedProject.openTime !== undefined ?
                                      <td className="text-end">
                                        <h5 className="mb-0" id="idBusdConvert">
                                          <span className="text-nowrap">1 USD</span>
                                          <span className="text-nowrap"> = {selectedProject['rate'].toFixed(4)} {selectedProject.projectTokenSymbol}</span>
                                        </h5>
                                      </td>
                                      : <td className="text-end">
                                        <h5 className="mb-0" id="idBusdConvert">TBA</h5>
                                      </td>
                                  }
                                </tr>

                                <tr>
                                  <td>Cap</td>
                                  {
                                    selectedProject.openTime !== undefined ?
                                      <td className="text-end">
                                        <h5 className="mb-0">{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</h5>
                                      </td>
                                      : <td className="text-end">
                                        <h5 className="mb-0">TBA</h5>
                                      </td>
                                  }
                                </tr>
                                <tr>
                                  <td>Total Users Participated</td>
                                  <td className="text-end">
                                    <h5 className="mb-0">{selectedProject.totalCountUserParticipated || 0}</h5>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Funds Swapped</td>
                                  {
                                    selectedProject.openTime !== undefined ?
                                      <td className="text-end">
                                        <h5 className="mb-0 text-nowrap">{selectedProject.totalFundParticipated.toFixed(4) || 0} {selectedProject.symbol || ""}</h5>
                                      </td>
                                      : <td className="text-end">
                                        <h5 className="mb-0">0</h5>
                                      </td>
                                  }

                                </tr>
                                <tr>
                                  <td>Access Type</td>
                                  <td className="text-end">
                                    <h5 className="mb-0">{selectedProject.isPrivate ? "Private" : "Public"}</h5>
                                  </td>
                                </tr>
                                <tr><td className="pb-3" colSpan={2}><br /></td></tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="pp-p-info">
                          <div className="pp-allocation-title">
                            <h4 className="pp-title-section info text-start fs-32 mb-4">Token information</h4>
                          </div>
                          <div className="table-responsivex">
                            <table className="table mb-0 pp-table-info text-white">
                              <tbody>
                                <tr>
                                  <td>Name</td>
                                  <td className="text-end">
                                    <h5 className="mb-0">{selectedProject.name}</h5>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Token Symbol</td>
                                  <td className="text-end">
                                    <h5 className="mb-0">{selectedProject.projectTokenSymbol}</h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <ScheduleTabComponent roundInfo={roundInfo} />
                      </div>
                    </div>
                  </div>
                </div>


                {yourAllocationVisible &&
                  <YourAllocationComponent
                    allocationInfo={allocationInfo}
                    tokenSymbol={selectedProject.projectTokenSymbol}
                    decimals={projectState.decimals}
                    contractAddress={contractAddress}
                    tokenAddress={projectState.projectTokenContract}
                    handleBuyClick={() => setCountBuy(countBuy + 1)}
                    claim={selectedProject.claim}
                    layout={layoutAllocation}
                  />
                }

              </div>
            </div>
          </div>
        </div>
        :
        <div className="pp-detail-page" style={{ backgroundImage: 'none' }}>
          <div className="pp-detail-banner">
            <div className="container py-5 text-center" style={{ fontSize: '20px' }}>
              Loading...
            </div>
          </div>
        </div>
      }

      <InvestmentModal walletInfo={walletInfo}
        allocationNumber={allocationNumber}
        remainingAllocation={remainingAllocation} handleBuyClick={() => setCountBuy(countBuy + 1)}
        countClick={countBuy}
        tokenBalance={helpers.formatNumberDownRound(tokenBalance, 4)}
        symbol={selectedProject?.symbol}
        handleInputAmount={(n) => setAmountPurchased(n)} />

      <ApproveModal
        remainingAllocation={remainingAllocation}
        walletInfo={walletInfo}
        handleBuyClick={() => setCountBuy(countBuy + 1)}
        handleInputAmount={(n) => setAmountPurchased(n)}
        tokenBalance={helpers.formatNumberDownRound(tokenBalance, 4)} />


    </>
  );
};

export default ProjectDetailPage;

import React from "react";
import { Container } from "react-bootstrap";

const CookiesPolicyPage = () => {
    return <div className="pp-term-page">
        <Container>
            <div className="pp-term-page-title">Cookies Policy</div>
        </Container>
    </div>
}
export default CookiesPolicyPage;
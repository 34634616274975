import React from "react";
import { Link } from "react-router-dom";
import { getCountDown, remainTime } from "../../../utils/helper";
import { useWaitingProject } from "../../../hook/useState";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const WaitingProjectsComponent = () => {
  const waitingProjects = useWaitingProject();
  return (
    <div className="pp-projects pp-projects-waiting">
      <div className="container">
        <h2 className="pp-title-section">
          <span>Projects Coming soon</span>
        </h2>
        <div className="row gx-md-5 justify-content-xl-center">
          {waitingProjects.length > 0 ?
            waitingProjects.map((item, key) => {
              getCountDown(`idOpenTime-${item["contract"]}-${item["openTime"]}`, item.openTime * 1000, function start(job) {
              }, function end(job) {
              })
              return (
                <div className="col-xl-4 col-lg-6 col-md-6" key={key}>
                  <div className="pp-id-card">
                    <div className="pp-id-card-header">
                      <div className="pp-id-card-image">
                        <img src={item.logo} alt={item.name} />
                      </div>
                      <div className="pp-id-card-tag">{item.symbol}</div>
                      {item.state === "P" ?
                        <>
                          {(item.contract === 'TBA' || item.openTime === 0 || item.rate == 0) ?
                            <div className="pp-id-card-status open">Opens in TBA<b></b></div>
                            : <div className="pp-id-card-status open">
                              <small className="me-2">Opens in</small>
                              <span id={`idOpenTime-${item["contract"]}-${item["openTime"]}`}>{remainTime(item.openTime * 1000)}</span>
                            </div>
                          }
                        </>
                        : <></>
                      }
                    </div>
                    <div className="pp-id-card-body">
                      <div className="pp-id-card-socials">
                        {
                          item.pancakeswap &&
                          <a href={item.pancakeswap} target="_blank"><img height="16" src="/images/pancake-swap.png" /></a>
                        }
                        {
                          item.website &&
                          <a href={item.website} target="_blank"><i className="fas fa-globe"></i></a>
                        }
                        {
                          item.twitter &&
                          <a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a>
                        }
                        {
                          item.medium &&
                          <a href={item.medium} target="_blank"><i className="fab fa-medium-m"></i></a>
                        }
                        {
                          item.telegram &&
                          <a href={item.telegram} target="_blank"><i className="fab fa-telegram"></i></a>
                        }
                      </div>
                      <OverlayTrigger placement={"top-start"} overlay={<Tooltip>{item.name}</Tooltip>}>
                        <Link to={(item.contract && item.contract !== 'TBA') ? `projects/${item.contract}` : `#`} className="pp-id-card-name">
                          <span>{item.name}</span>
                        </Link>
                      </OverlayTrigger>
                      <div className="pp-id-card-description">{item.description}</div>
                      <div className="pp-id-card-detail">
                        <ul>
                          <li>
                            <div className="pp-id-card-label">Swap rate</div>
                            <div className="pp-id-card-value">
                              {(item["contract"] && item.contract !== 'TBA')?
                                `1 ${item.symbol} = ${item["rate"]} ${item["projectTokenSymbol"]}`:"TBA" }
                            </div>
                          </li>
                          <li>
                            <div className="pp-id-card-info">
                              <div className="pp-id-card-sub-info">
                                <div className="pp-id-card-label">Cap</div>
                                <div className="pp-id-card-value">
                                {(item["contract"] && item.contract !== 'TBA')?`${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`:"TBA"}
                                </div>
                              </div>
                              <div className="pp-id-card-sub-info">
                                <div className="pp-id-card-label">Access</div>
                                <div className="pp-id-card-value">
                                  {item.isPrivate ? "Private" : "Public"}
                                </div>
                              </div>
                            </div>
                          </li>
                          {/* <li>
                          {
                            <div className={`pp-id-card-progress-wrap ${item.state == 'O' ? 'disabled' : ''}`}>
                              <div className="pp-id-card-progress-label">Progress</div>
                              <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
                                {item.contract !== 'TBA' ? item.state != 'P' && <div className="pp-id-card-progress-value">
                                  <span>{item['totalCountUserParticipated']}</span>
                                  <small>Participants</small>
                                </div> : <></>
                                }
                              </div>
                              <div className="pp-id-card-progress-bar">
                                <div className="pp-id-card-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                              </div>
                              <div className="pp-id-card-progress-values">
                                <div className="pp-id-card-progress-value sm">
                                  {((item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%
                                </div>
                                {
                                  item.state == 'O' ? <div className="pp-id-card-progress-value sm">
                                    <span>{item['totalCountUserParticipated']}</span>
                                    <small>Participants</small>
                                  </div> : <></>
                                }
                                <div className="pp-id-card-progress-value sm">
                                  {item.totalFundParticipated.toFixed(4)}/{item.maxTotalParticipationAllocated}
                                </div>
                              </div>
                            </div>
                          }
                        </li> */}
                        </ul>
                      </div>
                      {
                        item.button_name && <div className="pp-id-card-action">
                          <a href={item.button_url} target="blank" className="btn-pp btn-pp-sm">
                            <span>{item.button_name}</span>
                          </a>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )
            })
            :
            <div className="fs-mb-16 text-center">
              No projects currently coming soon
            </div>
          }
        </div>
      </div>
    </div >
  );
};

export default WaitingProjectsComponent;

import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicyPage = () => {
    return <div className="pp-term-page">
        <Container>
            <div className="pp-term-page-title">Privacy Policy</div>
        </Container >
    </div >
}
export default PrivacyPolicyPage;
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ACTION_CONST } from "../../../constants";
import { helpers } from "../../../utils/helpers";
import { getCountDown } from "../../../utils/helper";
import { useTranslation } from "react-i18next";
import { useActiveWeb3React } from "../../../hook";
import { useLatestBlock, useStatusSubmit, useWeb3Utils } from "../../../hook/useState";

const RightBar = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { account, library } = useActiveWeb3React()
  const web3Utils = useWeb3Utils();
  const latestBlock = useLatestBlock()
  const [stakedAmount, setStakedAmount] = useState('0');
  const [unstakedAmount, setUnstakedAmount] = useState('0');
  const [rewardAmount, setRewardAmount] = useState('0');
  const [withdrawTimestamp, setWithdrawTimestamp] = useState(0);
  const submitOK = useStatusSubmit()

  useEffect(() => {
    if (web3Utils && account) {
      // get stake amount
      web3Utils.getStakingInfoWallet().then(data => {
        setStakedAmount(data.stakedAmount);
        setUnstakedAmount(data.unstakedAmount);
        setRewardAmount(data.rewardAmount);

        const now = new Date().getTime();
        if (data.withdrawTimestamp > now) {
          setWithdrawTimestamp(data.withdrawTimestamp);
        }

        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });
    }
  }, [web3Utils, account, submitOK, dispatch, withdrawTimestamp, latestBlock])


  useEffect(() => {
    if (withdrawTimestamp > 0) {
      getCountDown(`${account}-endtime`, withdrawTimestamp, (job) => {

        dispatch({
          type: ACTION_CONST.SET_JOB_COUNTDOWN_STAKE_TIME,
          data: job
        });

      }, (job) => {
        setWithdrawTimestamp(0);
      });
    }
  }, [dispatch, account, withdrawTimestamp])

  //handle button withdraw click 
  const handleExcWithDraw = () => {


    if (web3Utils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      web3Utils.stakingExecuteWithdrawRewards(data => {
        if (data.status === "STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS") {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: t("Withdraw stake successfully")
          })

        }
        if (data.status === "STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL") {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: t("Withdraw stake fail")
          })
        }
      })
    }
    //to do somrthing
  }

  //stake reward
  const handleExStakeRewards = async () => {
    if (web3Utils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      web3Utils.stakingRewards(data => {
        if (data.status === "STAKING_REWARDS_SUCCESS") {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: t("Stake rewards successfully")
          })

        }
        if (data.status === "STAKING_REWARDS_FAIL") {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: t("Stake rewards fail")
          })
        }
      })
    }
  }

  return (
    <>
      <div className="col-lg-6">
        <div className="p-sidebar-card">
          <div className="p-sidebar-card-icon">
            <img src="/images/y.svg" alt="" height={80} />
          </div>
          <div className="p-sidebar-card-tag">
            <button className="btn btn-sm btn-outline-primary">
              <span>YOUR STAKED</span>
            </button>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-4 col-5">
              <div className="p-sidebar-card-title">{t('Your Staked')}</div>
              <div className="p-sidebar-card-value">{helpers.formatNumberDownRoundWithExtractMax(stakedAmount, 4)}</div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-4 col-7">
              <div className="p-sidebar-card-title">{t('Your Rewards')} ($MATIC)</div>
              <div className="p-sidebar-card-value">{helpers.formatNumberDownRoundWithExtractMax(rewardAmount, 4)}</div>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-4 mt-xl-0 mt-lg-4 mt-md-0 mt-4">
              <button className="btn btn-primary px-3 w-100"
                disabled={!account || parseFloat(rewardAmount) === 0 || parseFloat(unstakedAmount) > 0}
                onClick={() => handleExcWithDraw()}>{t('Withdraw')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RightBar;

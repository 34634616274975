

export const  MODE = process.env.REACT_APP_MODE || 'TESTNET';

export const POOL_INTERVAL = 15000;

export const EXPLORER = {
    MAINNET:'https://polygonscan.com',
    TESTNET:'https://evmexplorer.testnet.velas.com'
}
export const BACKEND_URI = {
    MAINNET:'https://app.polypad.com',   //
    // MAINNET:'https://t.nftlaunch.network',  
    TESTNET:'https://staging-tp.cloud'
}

export const STAKING_CONTRACT_ADDRESS ={
    // MAINNET:'0xdb0422A1C78C2064Ce5Af1B75412294F5B6D7Edf', //velas  ==>test
    MAINNET:'0xA595Ea2DAAD5965D7907A039bE0d954d948a76b8', //polygon
    TESTNET:'0x5f2DFeB7F4f42409f407d6Af56E7CAB59E20Eb0A'
}
export const NODE_URI ={

    // MAINNET:['https://mainnet.velas.com/rpc'], //velas  ==> test
    MAINNET:['https://sly-evocative-thunder.matic.quiknode.pro/b16d1be58b9f1587dba2c92ec34b69bc871a7597/'], // polygon
    TESTNET:['https://testnet.velas.com/rpc']
}


export const KYC_BACK_END_URI="https://bscpad.com"

export const supportChainIds = [ 137];
export const defaultChainId = process.env.REACT_APP_MODE === "TESTNET" ? 111 : 137; 

//mainnet 137; testnet 111 ; dev 5705

export const AMOUNT_SHOW_KYC = 1000.0
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ACTION_CONST, ACTION_STATUS, ROUTES } from "../../../constants";
import { STAKING_CONTRACT_ADDRESS, EXPLORER, MODE } from "../../../_configs";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../../utils";
import { useTranslation } from "react-i18next";
import { useActiveWeb3React } from "../../../hook";
import { useStakingInfo, useStakingWalletInfo, useETHBalance, usePolyPadBalance, useWeb3Utils } from "../../../hook/useState";

const StakingTab = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { account } = useActiveWeb3React()
    const web3Utils = useWeb3Utils()
    const stakingInfo = useStakingInfo();
    const stakingWalletInfo = useStakingWalletInfo()
    const ethBalance = useETHBalance()
    const polyPadBalance = usePolyPadBalance()
    const [acceptTerm, setAcceptTerm] = useState(false);

    const [swapAmount, setSwapAmount] = useState('0')
    const [isMaxAmount, setIsMaxAmount] = useState(false);

    const [swapCurrentStep, setSwapCurrentStep] = useState(1);
    const [enableSwapBtn, setEnableSwapBtn] = useState(false);

    const [transactionHash, setTransactionHash] = useState("")
    const [isSubmitOK, setIsSubmitOK] = useState(false);


    useEffect(() => {
        if (swapCurrentStep === 1) {
            if (acceptTerm &&
                account &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(polyPadBalance) > 0 &&
                ethBalance > 0
            ) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }, [acceptTerm, account, ethBalance, stakingInfo, stakingWalletInfo, swapCurrentStep, polyPadBalance])


    const swapBack = () => {
        if (swapCurrentStep === 1) {
            return;
        } else {
            setSwapCurrentStep(swapCurrentStep - 1);
        }
    }

    const swapNext = async () => {
        if (swapCurrentStep === 5) return;

        if (swapCurrentStep === 1 && parseFloat(swapAmount) === 0) {
            setEnableSwapBtn(false);
            setSwapCurrentStep(swapCurrentStep + 1);
            return;
        }

        if (swapCurrentStep === 3) {
            // click approve
            if (web3Utils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                });

                const allowance = await web3Utils.getAllowance(stakingInfo["tokenAddr"], STAKING_CONTRACT_ADDRESS[MODE]);

                if (new BigNumber(allowance).gte(isMaxAmount ? new BigNumber(polyPadBalance) : new BigNumber(swapAmount))) {

                    setSwapCurrentStep(4);
                    return dispatch({
                        type: ACTION_CONST.REQUEST_DONE
                    });

                }
                web3Utils.approve({ tokenContractAddress: stakingInfo["tokenAddr"], contractAddress: STAKING_CONTRACT_ADDRESS[MODE], amount: isMaxAmount ? polyPadBalance : swapAmount , decimal:18}, (data) => {
                    //function success
                    if (data.status === "APPROVED") {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        });
                        dispatch({
                            type: ACTION_CONST.ALERT_SUCCESS,
                            message: t("Approve Tokens successfully!")
                        });
                        setSwapCurrentStep(4);
                    }
                    //function fails
                    if (data.status === "APPROVE_FAILS") {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: t("Failed to Approve Tokens!")
                        })
                    }
                })

                // debugger

            }
        } else if (swapCurrentStep === 4) {
            // click deposit
            if (web3Utils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                web3Utils.stakingDeposit({ amount: isMaxAmount ? polyPadBalance : swapAmount }, (result) => {
                    if (result.status === ACTION_STATUS.STAKING_DEPOSIT_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(result.txID)

                        //change to result screen
                        setSwapCurrentStep(5);

                    }
                    if (result.status === ACTION_STATUS.STAKING_DEPOSIT_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: t("Deposit stake fail!")
                        })
                    }
                })
            }
        } else {
            setSwapCurrentStep(swapCurrentStep + 1);
        }
    }


    //handle input swap change
    const handleInputSwap = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setSwapAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(polyPadBalance)) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }

    const submitDone = () => {
        setSwapAmount('0');
        setIsMaxAmount(true);
        setSwapCurrentStep(1);
        setIsSubmitOK(false);
    }

    const handleMaxButtonClick = () => {
        setSwapAmount(helpers.formatNumberDownRound(polyPadBalance, 4));
        setEnableSwapBtn(true);
        setIsMaxAmount(true);
    }

    return (
        <div className="tab-pane fade show active" id="stake" role="tabpanel" aria-labelledby="stake-tab">
            <h2 className="project-section-title mb-5 d-flex flex-wrap justify-content-start">
                <b>Stake</b>
                <span className="ms-3 me-0">$PolyPad</span>
            </h2>
            <div className="pp-tab-content">
                <div className="row justify-content-center mb-5">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                <div className={`step ${swapCurrentStep === 1 ? 'active' : ''}`} id="swapButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label first">Checkpoints</span>
                                    </button>
                                </div>
                                <div className="line" id="swapLineStep1" />
                                <div className={`step ${swapCurrentStep === 2 ? 'active' : ''}`} id="swapButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Amount Stake</span>
                                    </button>
                                </div>
                                <div className="line" id="swapLineStep2" />
                                <div className={`step ${swapCurrentStep === 3 ? 'active' : ''}`} id="swapButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Pre-authorization</span>
                                    </button>
                                </div>
                                <div className="line" id="swapLineStep3" />
                                <div className={`step ${swapCurrentStep === 4 ? 'active' : ''}`} id="swapButtonStep4">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Confirm</span>
                                    </button>
                                </div>
                                <div className="line" id="swapLineStep4" />
                                <div className={`step ${swapCurrentStep === 5 ? 'active' : ''}`} id="swapButtonStep5">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="p-content-card p-content-card-steps">
                            <div className="p-content-card-body">
                                {/* Step 1 */}
                                {
                                    swapCurrentStep === 1 ?
                                        <div className="bs-stepper-content" id="SwapStep1">
                                            <div className="row gx-lg-5">
                                                <div className="col-lg-4">
                                                    <div className="step-header mt-lg-5 pt-lg-5">
                                                        <h1>{t('Checkpoints')}</h1>
                                                        <p>{t('The following conditions must be met to proceed')}:</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8">
                                                    <div className="row mt-4 d-flex justify-content-center gx-xl-5">
                                                        <div className="col-md-6">
                                                            <div className={account ? "p-select-card selected" : "p-select-card"}>
                                                                <img src={account ? '/images/globe.svg' : '/images/globex.svg'} alt="" />
                                                                <div className="p-select-card-title"><b>{t('Connected with MetaMask')}</b></div>
                                                                <div className="p-select-card-description">{t("If not connected, click the \"Connect Wallet\" button in the top right corner")}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className={parseFloat(polyPadBalance) > 0 ? "p-select-card selected" : "p-select-card"}>
                                                                <img src={parseFloat(polyPadBalance) > 0 ? '/images/globe.svg' : '/images/globex.svg'} alt="" />
                                                                <div className="p-select-card-title"><b> {t('$PolyPad available to deposit')}</b></div>
                                                                <div className="p-select-card-description">{t('Current Balance')}: {helpers.formatNumberDownRoundWithExtractMax(polyPadBalance, 4)}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className={ethBalance > 0 ? "p-select-card selected" : "p-select-card"}>
                                                                <img src={ethBalance > 0 ? '/images/globe.svg' : '/images/globex.svg'} alt="" />
                                                                <div className="p-select-card-title"><b>{t('MATIC available in wallet')}</b></div>
                                                                <div className="p-select-card-description">
                                                                    {t('MATIC is required to pay transaction fees on the Polygon Network.')} {t('MATIC Balance')}: {helpers.formatNumberDownRoundWithExtractMax(ethBalance, 4)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className={stakingWalletInfo["withdrawTimestamp"] === 0 ? "p-select-card selected" : "p-select-card"}>
                                                                <img src={stakingWalletInfo["withdrawTimestamp"] === 0 ? '/images/globe.svg' : '/images/globex.svg'} alt="" />
                                                                <div className="p-select-card-title"><b>{t('Eligible to stake')}</b></div>
                                                                <div className="p-select-card-description">{t('You cannot stake if you have an active $PolyPad unstake/withdrawal request')}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4">
                                                        <div className="form-check">
                                                            <input className="form-check-input float-none me-1"
                                                                type="checkbox" defaultValue id="flexCheckDefault"
                                                                onChange={() => setAcceptTerm(!acceptTerm)} />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                {t('I have read the')}&nbsp;
                                                                <Link target="_blank" to={ROUTES.TERMS_OF_USE}>{t('Terms and Conditions')} </Link>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <></>
                                }

                                {/* Step 2 */}
                                {
                                    swapCurrentStep === 2 ?
                                        <div className="bs-stepper-content" id="SwapStep2">
                                            <div className="row gx-xl-5">
                                                <div className="col-lg-4">
                                                    <div className="step-header pt-xl-3 text-center text-lg-start">
                                                        <h1>{t('Amount Stake')}</h1>
                                                        <p className="text-white">{t('Please enter the amount of $PolyPad you want to stake')}:</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8">
                                                    <div className="mt-4 text-center text-lg-start">
                                                        <p className="fs-18 mb-2">
                                                            {t('Any MATIC rewards already accumulated will be automatically paid out when staking.')}
                                                        </p>
                                                        <p className="fs-18">
                                                            {t('Any fees applied at Unstake & Withdraw stage will be based on the date you last staked.')}
                                                        </p>
                                                    </div>
                                                    <div className="pp-input-amount">
                                                        <label className="pp-input-amount-label">{t('Amount')}</label>
                                                        <div className="pp-input-amount-control">
                                                            <input type="number" id="inputAmountSwap" className="form-control form-control-lg form-control-input-group" placeholder={0.0} value={swapAmount} onChange={(e) => handleInputSwap(e)} />
                                                            <button className="btn btn-sm btn-primary" onClick={() => handleMaxButtonClick()}>{t('MAX')}</button>
                                                        </div>
                                                        <div className="pp-amount-balance">
                                                            {t('Balance')}: <b>{helpers.formatNumberDownRound(polyPadBalance, 4)}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <></>
                                }

                                {/* Step 3 */}
                                {
                                    swapCurrentStep === 3 ?
                                        <div className="bs-stepper-content text-center" id="SwapStep3">
                                            <div className="step-header">
                                                <h1>{t('Pre-authorization')}</h1>
                                                <p>
                                                    <small><i>({t('Required transaction 1 of 2')})</i></small>
                                                </p>
                                            </div>
                                            <p className="fs-18">
                                                {t('In this step, you grant access to the staking smart contract to accept your $PolyPad')}
                                            </p>
                                            {/* <div className="confirm-icon align-items-center m-0 mx-auto">
                                                <img className="spinnerx" src="/images/loader.svg" alt="loader" width="90" height="90" />
                                            </div> */}
                                            {/* <p className="fs-18 mb-2">{t('Waiting for the transaction to complete')}</p> */}
                                            {/* <p className="fs-18">{t('Please wait for the transaction to confirm before proceeding.')}</p> */}
                                        </div> : <></>
                                }

                                {/* Step 4 */}
                                {
                                    swapCurrentStep === 4 ?
                                        <div className="bs-stepper-content text-center" id="SwapStep4">
                                            <div className="step-header">
                                                <h1>{t('Confirm')}</h1>
                                                <p><small><i>({t('Required transaction 2 of 2')})</i></small></p>
                                            </div>
                                            <p className="fs-18 mb-2">{t('In this step, you deposit the tokens into the staking contract.')}</p>
                                            <p className="fs-18">{t('After this step, your tokens will be successfully staked.')}</p>
                                        </div> : <></>
                                }

                                {/* Step 5 */}
                                {
                                    swapCurrentStep === 5 ?
                                        <div className="bs-stepper-content" id="SwapStep5">
                                            <div className="text-center">
                                                <div className="text-info">
                                                    <i className="mdi mdi-check" style={{ fontSize: '90px', lineHeight: 1 }} />
                                                </div>
                                                <div className="step-header">
                                                    <h1>{t('Success')}</h1>
                                                </div>
                                                <p className="fs-18 mb-2">{t('Congratulations! Your tokens are now staked.')}</p>
                                                <p className="fs-18">{t('If desired, you may check Polygon Network to confirm the transaction.')}</p>
                                                <p className="fs-18"><a className="text-white" href={`${EXPLORER[MODE]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                            </div>
                                        </div> : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-4 mt-5 text-center">
                    {
                        !isSubmitOK ?
                            <>
                                <button className="btn btn-outline-primary me-4" onClick={() => swapBack()} type="button" disabled={!enableSwapBtn || swapCurrentStep === 1}>
                                    <span className="px-1"><i className="mdi mdi-arrow-left me-2" />{t('Previous')}</span>
                                </button>
                                <button className="btn btn-primary" onClick={() => swapNext()} type="button" disabled={!enableSwapBtn || swapCurrentStep === 5}>
                                    <span className="px-1"><i className="mdi mdi-arrow-right me-2" />{t('Next')}</span>
                                </button>
                            </>
                            :
                            <>
                                <button className="btn btn-primary" onClick={() => submitDone()} type="button">
                                    <span className="px-5">{t('Done')}</span>
                                </button>
                            </>
                    }
                </div>
            </div>
        </div>


    );
};

export default StakingTab;

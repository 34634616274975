import React from 'react';
import { useDispatch } from "react-redux";
import { ACTION_CONST } from "../../constants";
import useCopyToClipboard from './CopyToClibboard';
import { EXPLORER, MODE } from '../../_configs';
import useAuth from '../../hook/useAuth';
import { useActiveWeb3React } from '../../hook';

const WalletModal = () => {
    const dispatch = useDispatch();
    const [copied, setCopied] = useCopyToClipboard(1000);
    const { logout } = useAuth();
    const { account } = useActiveWeb3React()

    const handleLogout = () => {
        logout()
        dispatch({
            type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
        })
        window.location.reload();
    }

    return (
        <>
            <div className="modal fade pp-modal-wallet" id="walletModal" tabIndex="-1" aria-labelledby="walletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="walletModalLabel">Your wallet</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-5 text-center">
                                <h5 className="pp-address-text">
                                    {account}
                                </h5>
                                <div>
                                    <a href={`${EXPLORER[MODE]}/address/${account}`} target="_blank" className="btn btn-sm btn-outline-primary me-3">
                                        <span className="me-1">View on PScan</span><i className="mdi mdi-open-in-new"></i>
                                    </a>
                                    <button className="btn btn-sm btn-outline-primary" type="button" onClick={() => setCopied(account)}>
                                        <span className="me-1">Copy Address</span>{!copied ? <i className="mdi mdi-content-copy"></i> : <i className="mdi mdi-check"></i>}
                                    </button>
                                </div>
                            </div>
                            <div className="text-center">
                                <button onClick={handleLogout} data-bs-dismiss="modal" className="btn btn-primary">
                                    <span>Logout</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WalletModal;



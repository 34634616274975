import React from "react";
import { Container } from "react-bootstrap";

const TermOfUsePage = () => {
    return <div className="pp-term-page">
        <Container>
            <div className="pp-term-page-title">Term Of Use</div>
        </Container>
    </div>
}
export default TermOfUsePage;
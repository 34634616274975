import React, { useEffect, useState, } from "react";
import { ACTION_CONST } from "../../../constants";
import { useDispatch } from "react-redux";
import { helpers } from "../../../utils/helpers";
import { useSelectedProject, useWeb3Utils } from "../../../hook/useState";


const ApproveModal = (props) => {
  const dispatch = useDispatch();
  const selectedProject = useSelectedProject();
  const web3Utils = useWeb3Utils();
  const [projectName, setProjectName] = useState("")
  const [symbol, setSymbol] = useState("")
  const [amount, setAmount] = useState(0);
  const [enableAprBtn, setEnableAprBtn] = useState(false);

  useEffect(() => {
    if (selectedProject) {
      setProjectName(selectedProject.name)
      setSymbol(selectedProject.symbol);
    }
  }, [selectedProject]);

  const handleOnchangeAmount = (e) => {
    const amountInputValue = Number(e.target.value);
    if (0 < amountInputValue
      && amountInputValue <= props.walletInfo.remainingAllocation
      && amountInputValue <= props.walletInfo.tokenBalance) {
      setEnableAprBtn(true);
    } else {
      setEnableAprBtn(false)
    }
    setAmount(amountInputValue)
    props.handleInputAmount(amountInputValue)
    return;
  }

  const handleClickMax = () => {
   
    if (props.walletInfo.tokenBalance === 0 || props.walletInfo.remainingAllocation === 0) {
      setEnableAprBtn(false);
      return;
    }
    if (props.walletInfo.tokenBalance > 0 && props.walletInfo.remainingAllocation <= props.walletInfo.tokenBalance) {
      
      setAmount(props.walletInfo.remainingAllocation);

      document.getElementById('inputAmountApprove').value = helpers.formatNumberDownRound(props.walletInfo.remainingAllocation, 4);
      setEnableAprBtn(true);
      return;
    }
    if (props.walletInfo.remainingAllocation > 0 && props.walletInfo.remainingAllocation > props.walletInfo.tokenBalance) {
      setAmount(helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 4))
      document.getElementById('inputAmountApprove').value = helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 4)
      setEnableAprBtn(true);
      return;
    }
    setEnableAprBtn(false);

  }

  const handleApprove = () => {
    if (web3Utils && selectedProject) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      // console.log("amount=>", amount);
      web3Utils.approve({
        contractAddress: selectedProject.contract,
        tokenContractAddress: selectedProject.tokenAddress,
        amount: Number(amount),
        decimal: selectedProject.decimal
      }, (data) => {

        if (data.status == "APPROVED") {
          // setShowBuyBtn(true)
          // setShowAprBtn(false)
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          props.handleBuyClick()

          document.getElementById("inputAmountApprove").value = 0;
          setEnableAprBtn(false);
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: "Approve Success"
          })
        }
        if (data.status == "APPROVE_FAILS") {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: "Failed to Approve Tokens"
          })
        }

      }).catch(err => {
        dispatch({
          type: ACTION_CONST.ALERT_SUCCESS,
          message: "Token Approve Fails! Please try again!"
        })
        dispatch({ type: ACTION_CONST.REQUEST_DONE })
      })
    }
  }

  return (
    <div
      className="modal fade"
      id="approveModal"
      tabIndex="-1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="approveModalLabel">Join {projectName} Pool</h5>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn-close me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="modal-body">
            {/* <BlockUi tag="div" blocking={showBlockUI}> */}
            <div className="pp-input-amount">
              <label className="pp-input-amount-label">{symbol} amount:</label>
              <div className="w-100 pp-input-amount-control">
                <input type="number" id="inputAmountApprove" className="form-control form-control-lg form-control-input-group" 
                 defaultValue={0} autoFocus={true} onChange={(e) => handleOnchangeAmount(e)} />
                <button className="btn btn-sm btn-primary" onClick={() => handleClickMax()}>MAX</button>
              </div>
              <div className="pp-amount-balance">Your balance: <b>{props?.tokenBalance} {props?.symbol}</b></div>
            </div>

            <div className="mt-5 mb-2" >
              <button type="button" className="btn-pp btn-pp-primary w-100" disabled={!enableAprBtn} onClick={() => handleApprove()} data-bs-dismiss="modal">
                <span>Approve</span>
              </button>
            </div>
            {/* </BlockUi> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveModal;





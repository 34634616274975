import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ACTION_CONST, ACTION_STATUS, ROUTES } from "../../../constants";
import { EXPLORER, MODE } from "../../../_configs";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../../utils";
import { useTranslation } from "react-i18next";
import { useStakingInfo, useStakingWalletInfo, useETHBalance, useWeb3Utils } from "../../../hook/useState";
import { useActiveWeb3React } from "../../../hook";

const UnStakingTab = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [unStakeCurrentStep, setUnStakeCurrentStep] = useState(1);
    const { account } = useActiveWeb3React()
    const web3Utils = useWeb3Utils();
    const stakingInfo = useStakingInfo();
    const stakingWalletInfo = useStakingWalletInfo()
    const ethBalance = useETHBalance()

    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableUnStakeBtn, setEnableUnStakeBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [unStakeAmount, setUnStakeAmount] = useState('0');
    const [stakedDuration, setStakedDuration] = useState(0);
    const [totalPctFee, setTotalPctFee] = useState(0);
    const [isMaxAmount, setIsMaxAmount] = useState(false);
    const [isSubmitOK, setIsSubmitOK] = useState(false);


    const cleanValue = () => {
        // setUnStakeCurrentStep(1);
    };
    useImperativeHandle(ref, () => {
        return {
            cleanValue: cleanValue
        }
    });
    //enable unstake button
    useEffect(() => {
        if (unStakeCurrentStep === 2) {

            if (acceptTerm &&
                account &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(stakingWalletInfo["stakedAmount"]) > 0 &&
                ethBalance > 0
            ) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
        if (unStakeCurrentStep === 1) setEnableUnStakeBtn(true);
        if (stakingInfo && stakingWalletInfo["stakedDuration"]) {
            setStakedDuration(stakingWalletInfo["stakedDuration"]);
            setTotalPctFee(stakingWalletInfo["totalPctFee"] / 100);
        }
    }, [acceptTerm, account, ethBalance, stakingInfo, stakingWalletInfo, unStakeCurrentStep])


    const unStakeBack = () => {
        if (unStakeCurrentStep === 1) {
            return;
        } else {
            setUnStakeCurrentStep(unStakeCurrentStep - 1);
        }
    }

    const unStakeNext = () => {
        if (unStakeCurrentStep === 1) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 2 && parseFloat(unStakeAmount) === 0) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 4) {
            if (web3Utils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                web3Utils.stakingInitiateWithdrawal({ amount: isMaxAmount ? stakingWalletInfo["stakedAmount"] : unStakeAmount }, data => {
                    // console.log(data);
                    if (data.status === ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true);
                        setTransactionHash(data.txID);

                        setUnStakeCurrentStep(5);

                    }
                    if (data.status === ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "Init Unstake fails"
                        })
                    }
                })
            }
        } else {
            if (unStakeCurrentStep === 5) {
                return;
            } else {
                setUnStakeCurrentStep(unStakeCurrentStep + 1);
            }
        }
    }

    const handleInputUnStake = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setUnStakeAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(stakingWalletInfo["stakedAmount"])) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
    }

    const handleMaxButtonClick = () => {
        setUnStakeAmount(helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 4));
        setEnableUnStakeBtn(true);
        setIsMaxAmount(true);
    }


    const submitDone = () => {
        setUnStakeAmount('0');
        setIsMaxAmount(true);
        setUnStakeCurrentStep(1);
        setIsSubmitOK(false);
    }

    return (

        <div className="tab-pane fade" id="unStake" role="tabpanel" aria-labelledby="unStake-tab">
            <h2 className="project-section-title mb-5 d-flex flex-wrap justify-content-end">
                <b>Unstake &amp; Withdraw</b>
                <span className="ms-3 me-0">your $PolyPad</span>
            </h2>
            <div className="pp-tab-content">
                <div className="row justify-content-center mb-5">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                <div className={`step ${unStakeCurrentStep === 1 ? 'active' : ''}`} id="unStakeButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label first">Warning</span>
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep1" />
                                <div className={`step ${unStakeCurrentStep === 2 ? 'active' : ''}`} id="unStakeButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Checkpoints</span>
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep2" />
                                <div className={`step ${unStakeCurrentStep === 3 ? 'active' : ''}`} id="unStakeButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Amount to Unstake</span>
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep3" />
                                <div className={`step ${unStakeCurrentStep === 4 ? 'active' : ''}`} id="unStakeButtonStep4">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Initialize Unstake</span>
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep4" />
                                <div className={`step ${unStakeCurrentStep === 5 ? 'active' : ''}`} id="unStakeButtonStep5">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="p-content-card p-content-card-steps">
                            <div className="p-sidebar-card-body">
                                {/* Step 1 */}
                                {
                                    unStakeCurrentStep === 1 ?
                                        <div className="bs-stepper-content" id="UnStakeStep1">
                                            <div className="row gx-lg-5">
                                                <div className="col-xl-4 col-lg-5">
                                                    <div className="step-header">
                                                        <h1><i className="fas fa-exclamation-triangle me-2"></i> {t('Warning')}</h1>
                                                        <p>
                                                            {t('You may be subject to a fee if you wish to unstake & withdraw your $PolyPad tokens early')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-xl-5 col-lg-6">
                                                    <div className="table-responsivex mb-3">
                                                        <table className="table bs-stepper-table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>{t('Staked less than 1 month')}</td>
                                                                    <td className="text-end">
                                                                        <h5 className="mb-0">25.00%</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Less than 2 months')}</td>
                                                                    <td className="text-end">
                                                                        <h5 className="mb-0">15.00%</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Less than 3 months')}</td>
                                                                    <td className="text-end">
                                                                        <h5 className="mb-0">10.00%</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('Less than 4 months')}</td>
                                                                    <td className="text-end">
                                                                        <h5 className="mb-0">5.00%</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t('4 months or more')}</td>
                                                                    <td className="text-end">
                                                                        <h5 className="mb-0">0.00%</h5>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <p className="fs-18 mb-2">{t('You have staked for')} [{helpers.convertSecondsToReadableString(stakedDuration)}]. {t('Your current fee is')}: <b className="pp-highlight">{totalPctFee}%</b></p>
                                                    <p className="fs-18">{t("Please click 'Next' if you wish to proceed.")}</p>
                                                </div>
                                            </div>
                                        </div> : <></>
                                }

                                {/* Step 2 */}
                                {
                                    unStakeCurrentStep === 2 ?
                                        <div className="bs-stepper-content" id="UnStakeStep2">
                                            <div className="row gx-xl-5">
                                                <div className="col-lg-4">
                                                    <div className="step-header mt-lg-5 pt-lg-5">
                                                        <h1>{t('Checkpoints')}</h1>
                                                        <p>{t('The following conditions must be met to proceed')}:</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8">
                                                    <div className="row mt-4 d-flex gx-xl-5">
                                                        <div className="col-md-6">
                                                            <div className={account ? "p-select-card selected" : "p-select-card"}>
                                                                <img src={account ? '/images/globe.svg' : '/images/globex.svg'} alt="" />
                                                                <div className="p-select-card-title"><b>{t('Connected with MetaMask')}</b></div>
                                                                <div className="p-select-card-description">{t('If not connected, click the "Connect Wallet" button in the top right corner')}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className={parseFloat(stakingWalletInfo["stakedAmount"]) > 0 ? "p-select-card selected" : "p-select-card"}>
                                                                <img src={parseFloat(stakingWalletInfo["stakedAmount"]) > 0 ? '/images/globe.svg' : '/images/globex.svg'} alt="" />
                                                                <div className="p-select-card-title"><b>{t('Have an active $PolyPad stake')}</b></div>
                                                                <div className="p-select-card-description">{t('You currently have')} {helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 2)} {t('$PolyPad staked')}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className={ethBalance > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                                <img src={ethBalance > 0 ? '/images/globe.svg' : '/images/globex.svg'} alt="" />
                                                                <div className="p-select-card-title"><b>{t('MATIC available in wallet')}</b></div>
                                                                <div className="p-select-card-description">
                                                                    {t('MATIC is required to pay transaction fees on the Polygon Network.')} {t('MATIC Balance')}: {helpers.formatNumberDownRoundWithExtractMax(ethBalance, 4)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4">
                                                        <div className="form-check">
                                                            <input className="form-check-input float-none me-1"
                                                                type="checkbox" defaultValue id="flexCheckDefault"
                                                                onChange={() => setAcceptTerm(!acceptTerm)} />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                {t('I have read the')}&nbsp;
                                                                <Link target="_blank" to={ROUTES.TERMS_OF_USE}>{t('Terms and Conditions')} </Link>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <></>
                                }
                                {/* Step 3 */}
                                {
                                    unStakeCurrentStep === 3 ?
                                        <div className="bs-stepper-content" id="UnStakeStep3">
                                            <div className="row gx-xl-5">
                                                <div className="col-lg-4">
                                                    <div className="step-header text-lg-start text-center">
                                                        <h1>{t('Amount to Unstake')}</h1>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7">
                                                    <p className="fs-18 pt-xl-3 text-lg-start text-center">{t('Please enter the amount you wish to Unstake & Withdraw')}</p>
                                                    <div className="pp-input-amount">
                                                        <label className="pp-input-amount-label">{t('Amount')}</label>
                                                        <div className="pp-input-amount-control">
                                                            <input type="number" id="inputAmountSwap" className="form-control form-control-lg form-control-input-group" placeholder={0.0} value={unStakeAmount} onChange={(e) => handleInputUnStake(e)} />
                                                            <button className="btn btn-sm btn-primary" onClick={() => handleMaxButtonClick()}>{t('MAX')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <></>
                                }

                                {/* Step 4 */}
                                {
                                    unStakeCurrentStep === 4 ?
                                        <div className="bs-stepper-content" id="UnStakeStep4">
                                            <div className="text-center">
                                                <div className="step-header">
                                                    <h1>{t('Initialize Unstake')}</h1>
                                                </div>
                                                {/* <div className="confirm-icon align-items-center m-0 mx-auto">
                                                    <img className="spinnerx" src="/images/loader.svg" alt="loader" width="90" height="90" />
                                                </div> */}
                                                <p className="fs-18 mb-2">{t('Confirm Unstaking & Withdraw Process')}</p>
                                                <p className="fs-18">{t('In this step, you initiate the process')}</p>
                                            </div>
                                        </div> : <></>
                                }

                                {/* Step 5 */}
                                {
                                    unStakeCurrentStep === 5 ?
                                        <div className="bs-stepper-content" id="UnStakeStep5">
                                            <div className="text-center">
                                                <div className="text-info">
                                                    <i className="mdi mdi-check" style={{ fontSize: '90px', lineHeight: 1 }} />
                                                </div>
                                                <div className="step-header">
                                                    <h1>{t('Confirmed')}</h1>
                                                </div>
                                                <p className="fs-18 mb-2">{t('You have initiated the process.')}</p>
                                                <p className="fs-18">{t('If desired, you may check Polygon Network to confirm the transaction.')}</p>
                                                <p className="fs-18"><a className="text-white" href={`${EXPLORER[MODE]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                            </div>
                                        </div> : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-4 mt-5 text-center">
                    {
                        !isSubmitOK ?
                            <>
                                <button className="btn btn-outline-primary me-4" onClick={() => unStakeBack()} type="button" disabled={!enableUnStakeBtn || unStakeCurrentStep === 1}>
                                    <span className="px-1"><i className="mdi mdi-arrow-left me-2" />{t('Previous')}</span>
                                </button>
                                <button className="btn btn-primary" onClick={() => unStakeNext()} type="button" disabled={!enableUnStakeBtn || unStakeCurrentStep === 5}>
                                    <span className="px-1"><i className="mdi mdi-arrow-right me-2" />{t('Next')}</span>
                                </button>
                            </>
                            :
                            <>
                                <button className="btn btn-primary" onClick={() => submitDone()} type="button">
                                    <span className="px-5">{t('Done')}</span>
                                </button>
                            </>
                    }
                </div>
            </div>


        </div>


    );
});

export default UnStakingTab;

import React from 'react';
import ConnectWalletModal from '../ConnectWalletModal';
import WalletModal from '../WalletModal';
import HelpModal from '../HelpModal';
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
import packageJson from '../../../../package.json';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="pp-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-5 text-lg-start text-center text-white-50">Copyright © {currentYear}. All Rights Reserved by PolyPad</div>
            <div className="col-xl-2 col-lg-1 text-center text-white-50 text-nowrap">Version {packageJson.version}</div>
            <div className="col-xl-5 col-lg-6">
              <ul className="pp-footer-menu justify-content-center justify-content-lg-end">
                <li><Link to={ROUTES.PRIVACY_POLICY} target="_blank">Privacy Policy</Link></li>
                <li><Link to={ROUTES.COOKIE_POLICY} target="_blank">Cookies Policy</Link></li>
                <li><Link to={ROUTES.TERMS_OF_USE} target="_blank">Terms &amp; Conditions</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ConnectWalletModal />
      <HelpModal />
      <WalletModal />
    </>
  );
}
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actGetListProjects } from "../../redux/action/user";
import WaitingProjectsComponent from "./components/WaitingProjectsComponent";
import OpeningProjectsComponent from "./components/OpeningProjectsComponent";
import ClosedProjectsComponent from "./components/ClosedProjectsComponent";
import { get } from "react-hook-form";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";

const ProjectsPage = () => {
  const dispatch = useDispatch();
  const latestBlock = useSelector((state) => get(state, "utils.latestBlock", 0));

  useEffect(() => {
    dispatch(actGetListProjects())
  }, [latestBlock])

  return (
    <div className="pp-projects-page">
      <div className="pp-projects-page-banner">
        <Container>
          <Row className="justify-content-between">
            <Col xl="6"></Col>
            <Col xl="5" className="text-center text-xl-end ps-xl-0">
              <h2>Unlocking the Opportunities<br />
                of the Burgeoning Cross-Chain Landscape...</h2>
              <div>PolyPad is here with a simple mission: to break down the barriers to accessing novel early-stage blockchain projects building on promising DLTs.</div>
            </Col>
          </Row>
          <div className="pp-projects-page-banner-action">
            <a href="https://polypad.com/" target="_blank" className="btn-pp-outline">
              <span>Homepage</span>
            </a>
            <a href="https://bridge.polypad.com/" target="_blank" className="btn-pp-outline">
              <span>Bridge</span>
            </a>
            <Link to={ROUTES.STACKING} className="btn-pp">
              <span>Staking</span>
            </Link>
          </div>
        </Container>
      </div>
      <OpeningProjectsComponent />
      <WaitingProjectsComponent />
      <ClosedProjectsComponent />
    </div>
  );
};

export default ProjectsPage;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Header from './shared/layout/Header';
import Footer from './shared/layout/Footer';
import { get } from "lodash";
import BlockUi from "react-block-ui"
import "react-block-ui/style.css";
import $ from 'jquery';

export const PrivateLayout = ({ children, ...rest }) => {
    const showBlockUI = useSelector((state) =>
        get(state, "utils.blocking", false)
    );
    $(window).on('scroll', function () {
        setHeaderClass();
    })

    const setHeaderClass = () => {
        var sc = $(window).scrollTop();
        if (sc > 0) {
            $('#PPNavbar').addClass('pp-navbar-fixed')
        } else {
            $('#PPNavbar').removeClass('pp-navbar-fixed')
        }
    }

    useEffect(() => {
        setHeaderClass();
    })

    return (
        <>
            <BlockUi tag="div" blocking={showBlockUI}>
                <Header />
                <div className='page-container'>
                    {children}
                </div>
                <Footer />
            </BlockUi>
        </>
    );
};